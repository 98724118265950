@import "assets/Colors/index.scss";

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 120px auto 20px auto;
  padding: 0 20px;
  max-width: 80%;
}

.return{
  transition: 100ms;
  cursor: pointer;
  &:active{
    filter: brightness(150%);
  }
}

.title{
  text-align: center;
  flex-grow: 1;
  margin-right: 6%;
  color: $title !important;
}

.container{
  display: flex;
  flex-direction: row;
  border: 2px solid #88C6D9;
  margin-bottom: 50px;
  padding: 0;
  border-radius: 5px;;
  max-width: 80%;
}

.info{
  display: flex;
  flex-direction: column;
  flex: 4;
  background-color: #88C6D9;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  gap: 25px;
}

.infoHeader{
  margin: 20px;
  margin-bottom: 0;
  font-size: 24px;
}

.infoForm{
  margin: 30px 30px 0;
}

.inputLabel{
  margin-bottom: 0;
}

.infoInput{
  margin-top: 0px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.usersList{
  position: absolute;
  margin-top: -20px;
  width: 100%;
  height: 195px;
  overflow-y: auto;
  z-index: 3;
  font-size: 15px;
  font-weight: 500;
  color: #5B5B5B;
}

.usersListItem{
  cursor: pointer;
  &:hover{
    background-color: #EEEEEE;
  }
}

.usersListItemEmpty{
  cursor: not-allowed;
  &:hover{
    background-color: #EEEEEE;
  }
}

.insertButton{
  background-color: #4FAAC5;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: fit-content;
  margin: 0px auto 15px;
  padding: 10px;
  font-weight: 500;
  text-align: center;
  transform: 100ms;
  cursor: pointer;
  &:hover{
    background-color: #3191AD;
  }
  &:active{
    filter: brightness(110%);
  }
}

.cancelButton{
  background-color: #C54F4F;
  border-radius: 5px;
  width: fit-content;
  margin: 0 auto 15px;
  padding: 10px 75px;
  font-weight: 500;
  text-align: center;
  transform: 100ms;
  cursor: pointer;
  &:hover{
    background-color: #B03636;
  }
  &:active{
    filter: brightness(110%);
  }
}

.questionSolution{
  display: flex;
  flex: 7;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
}

.containerQS{
  margin: 20px 50px;
}

.labelQS{
  font-size: 24px;
  font-weight: 700;
  color: #00799C;
}

.inputQS{
  width: 100%;
  height: 25vh;
  border: 1px solid #88C6D9;
  white-space: normal;
  padding: 0 0 250px 0;

}

.alertMessage{
  transform: 500ms;
  max-width: 80%;
  margin: auto;
  font-size: 22px;
  text-align: center;
  align-content: center;
}
