.button{
  margin: 4rem 0 0 4rem;
}

.rowCard{
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  width: 100%;
}

.card{
  justify-content: center;
}