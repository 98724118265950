.colLeft{
  text-align: left;
}

.colRight{
  text-align: right;
}

.standardSlider{
  margin: 10px 0;
  justify-content: center !important;
}
