@import '../../assets/Colors/index.scss';

.card{
  margin-top: 4rem;
}

.cardHeader{
  display: flex;
  justify-content: center;
  height: 6rem;
  align-items: center;
  background-color: white !important;
  border-bottom: none !important;
}

.cardTitle{
  align-items: center;
  color: $text;
  margin: 0 !important;
}

.inputPhone{
  gap: 1rem;
  display: grid;
  width: 100%;
}

.inputPhone div{
  gap: 1rem;
  display: flex;
  justify-content: space-between;
}

.inputPhone div div{
  display: none;
}

.inputPhone div select,
.inputPhone input{  
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  color: #212529;
  border-radius: 0.25rem;
  background-color: #FFFFFF;
  width: 100%;
}

.inputPhone div select::placeholder{
  color: #212529;
  
}

.cardBody{
  text-align: -webkit-center;
}

.body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
}

.checkedPassword{
  font-size: 12px;
  align-content: center;
  align-items: center;
  height: 1rem;
  color: $text;
}

.selectInput{
 text-transform: capitalize; 
}

.form{
  display: grid;
  gap: 1.5rem
}