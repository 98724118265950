@import 'assets/Colors/index.scss';

.search{
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title{
  text-align: center;
  color: $text;
  margin-bottom: 20px;
}

.center{
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

tbody{
  tr:hover{
    cursor: pointer;
  }
}