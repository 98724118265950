@import 'assets/Colors/index.scss';

.colorWhite{
  color: $white !important;
}

.width100{
  width: 100%;
}

.withMargin{
  margin: 2rem 0;
}