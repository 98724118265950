.row{
  margin: 1rem 0;
}

.form{
  margin: 1rem;
}

.btn{
  margin-top: 1rem;
}