@import "assets/Colors/index.scss";

.table{
  border-collapse: collapse;
  justify-self: center;
  max-width: 95%;
  font-family: Arial, sans-serif;
  font-size: 14px;
  white-space: normal;
  text-align: left;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  margin: auto;
  margin-top: 30px;
  cursor: default !important;
  border: #fff;
}

.thead{
  background-color: $secondaryLight !important;
  border-right: 2px solid #fff;
  border-radius: 5px 5px 0 0;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
  color: #fff !important;
  text-decoration: bold !important;
  height: 60px;
  &:last-child {
    border-right: 2px solid #88C6D9;;
  }
  &:first-child {
    border-left: 2px solid #88C6D9;;
  }
}

.trow{
  background-color: #fff;
  border-radius: 0px !important;
  transition: 200ms;
  cursor: inherit !important;
  &:nth-child(even) {
    background-color: #EEEEEE;
  }
  &:last-child {
    border-bottom: 2px solid #88C6D9;
  }
}

.tdata{
  border-right: 2px solid #88C6D9;
  border-left: 2px solid #88C6D9;
  background-color: transparent !important;
  vertical-align: middle;
  font-size: 12px;
  text-align: justify;
  color: #000 !important;
  border-radius: 0;
  word-break: break-word;
}

.customWidth{
  max-width: 300px;
}

.editWidth{
  max-width: 58px;
}

.edit{
  width: fit-content;
  color: #5B5B5B;
  text-decoration: underline;
  margin: auto;
  cursor: pointer;
  transition: 100ms;
  &:active{
    filter: brightness(50%);
  }
}