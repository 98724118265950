.locals{
  margin: 1rem 0 !important;
}

.textCity,
.textName{
  text-align: left;
}

.textStreet{
  text-align: center;
}

.textCep,
.textComplement{
  text-align: right;
}

@media (max-width:768px) {
  .textCity,
  .textName{
    text-align: center;
  }

  .textStreet{
    text-align: center;
  }

  .textCep,
  .textComplement{
    text-align: center;
  }
}

