@import'../../assets/Colors/index.scss';

.card{
  margin: 5px !important;
}

.cardHeader{
  padding: 4px !important;
}

.title{
  color: #eee;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  transition: all 0.1s linear;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.title:hover{
  color: rgb(184, 184, 184);
  cursor: pointer;
}

.fontTitle{
  font-size: 16px;
  color: #eee;
}

.deviceName{
  font-size: 14px;
  text-transform: uppercase;
}

.cardBody{
  color: $primary;
  padding: 8px !important;
}

.deviceHeader{
  background-color: $primary;
}

.cardBodyText,
.cardBodyTextDisconnect
{
  display: flex;
  justify-content: center;
  gap: 10%;
}

.cardBodyTextDisconnect{
  color: grey;
}

.cardBodyText{
  color: $primary;
}

.humidityText{
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
}

.arrowDown{
  color: grey;
}

.arrowDown:hover{
  cursor: pointer;
}

.dropDownOpen{
  display: flex;
  justify-content: space-around;
}

.formSwitch{
  display: flex !important;
  justify-content: center !important;
  margin-top: 1rem !important;
  gap: 0.5rem !important;
}

.rowSlider{
  margin:  1rem 0 !important;
}

.slider{
  width: 70% !important;
  margin: auto 0 !important;
}

.setPoint{
  color: $text !important;
  justify-content: center !important;
}