@import 'assets/Colors/index.scss';

.card{
  width: 100%;
  box-shadow: 0 0 0.2em $gray;
}

.devicesCard,
.devicesCardHeader,
.devicesCardBody,
.devicesCardBodyColumnColumn,
.devicesCardBodyColumnRow{
  display: flex;
  justify-content: center;
}

.ColQRcode{
  display: flex;
  justify-content: center;
  margin: auto;
  background-color: lightgray;
  border-radius: 6px;
  transition: 250ms;
}

.ColQRcode:hover{
  cursor: pointer;
}

.ColQRcode:active{
  background-color: rgb(165, 164, 164);
}

.devicesCard{
  margin: 20px;
}

.devicesCardHeader{
  background-color: $secondaryLight !important;
  border: none !important;
  color: $white !important;
}

.devicesCardTitle{
  margin-left: auto;
}

.devicesCardBody{
  flex-direction: column;
}

.devicesCardClose{
  margin: auto 0;
  margin-left: auto;
}

.devicesCardBodyColumnAround{
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin: 10px 0;
}

.devicesCardBodyColumnColumn{
  flex-direction: column;
  margin: 20px 0;
}

.devicesCardBodyColumnRow{
  flex-direction: row;
  margin: 10px 0;
  gap: 10px;
}

.devicesCardBodySetpoint{
  font-weight: bold;
  font-size: 20px;
  margin: auto 10px;
  color: $gray !important;
}

.devicesCardExpand:hover{
  cursor: pointer;
}

.textInfos{
  color: $text !important;
  text-align: center !important;
  margin-bottom: 16px;
}

.textInfosTitle{
  color: $text !important;
  font-weight: bold !important;
}

.slider{
  width: 70% !important;
  margin: auto 0 !important;
}

.text{
  color: $text !important;
}

.qrCodeButton{
  display: flex !important;
  height: 2.5rem !important;
  align-self: center !important;
  color: white !important
}

.closeModal{
  display: flex;
  justify-content: flex-end;
  align-self: center;
  cursor: pointer;
}

.modalHeader{
  display: flex;
  justify-content: space-between;
}

.modalTitle{
  display: flex;
  flex: 25;
  justify-content: center;
}

.modalBody{
  display: flex;
  justify-content: center !important;
}

.routineButton{
  display: flex !important;
  height: 2.5rem !important;
  align-self: center !important;
  justify-content: flex-end !important;
  color: white !important;
}

.Day{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-top: 0;
  padding-bottom: 30px;
}

.day_title{
  position: relative;
  top: 5px;
  text-align: center;
  font-size: 15px;
  margin: 0;
  height: 45px;
  color: $text;
}

.day_content{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.day_content div{
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.routineModal {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 950px;
  padding-top: 5px;
}

.Body{
  display: grid;
  grid-template-rows: auto auto auto auto auto auto auto;
  grid-template-columns: 100%;
  padding: 0;
  margin-top: 15px;
}

.Switch{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1.5rem;
}

.alertMessage{
  font-size: 20px;
  font-weight: 500;
  margin: 0 auto 15px ;
  background-color: #88C6D9;
  color: #fff;
  padding: 8px;
  border-radius: 2px;
}