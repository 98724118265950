@import '../../assets/Colors/index.scss';

.card{
  margin-top: 2.5rem;
  border-radius: 10px;
}

.cardHeader{
  border-radius: 10px;
}

.cardBody{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.cardBodyRow{
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}

.closeModal{
  cursor: pointer;
}

.modalBody{
  display: flex;
  justify-content: center !important;
}

.center{
  display: flex;
  justify-content: center;
}

.standardSlider{
  justify-content: center !important;
  
  &__text{
    text-align: center !important;
    gap: 5px !important;
    flex-wrap: nowrap !important;
  }

  &__params{
    display: inline !important;
    text-align: -webkit-center !important;
  }
}

.rowCenter{
  text-align: center !important;
  gap: 5px !important;
  flex-wrap: nowrap !important;
}

.displayInline{
  display: inline;
  text-align: -webkit-center;
}

.textAlignLeft{
  text-align: left;
  white-space: nowrap;
}

.textAlignRight{
  text-align: right;
  white-space: nowrap;
}

.standardButton{
  color: $white;
}

.groupButtons{
  text-align: center !important;
  margin: 1rem;
}

.buttons{
  margin-top: 2rem; 
}

.ColQRcode{
  display: flex;
  justify-content: center;
  margin: auto;
  background-color: lightgray;
  border-radius: 6px;
  transition: 250ms;
}

.ColQRcode:hover{
  cursor: pointer;
}

.ColQRcode:active{
  background-color: rgb(165, 164, 164);
}