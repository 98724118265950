@import "assets/Colors/index.scss";

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 120px auto 20px auto;
  padding: 0 20px;
  max-width: 95%;
}

.title{
  text-align: center;
  flex-grow: 1;
  margin-right: 4%;
  color: $title !important;
}

.toolBar{
  display: flex;
  width: fit-content;
  gap: 26px;
  align-items: center;
}

.reloadBox{
  display: flex;
  width: 45px;
  height: 45px;
  background-color: #4FAAC5;
  border-radius: 25px;
  transition: 100ms;
  cursor: pointer;
  &:active{
    filter: brightness(110%);
  }
  &:hover{
    background-color: #3191AD;
  }
}

.reload{
  margin: auto;
}


.insertBox{
  display: flex;
  background-color: #4FAAC5;
  border-radius: 5px;
  width: 140px;
  height: 40px;
  transition: 100ms;
  cursor: pointer;
  &:active{
    filter: brightness(110%);
  }
  &:hover{
    background-color: #3191AD;
  }
}

.insert{
  display: flex;
  width: fit-content;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin: auto;
  align-items: center;
}

.pagesBar{
  display: flex;
  justify-content: end;
}

.pagesBox{
  display: flex;
  margin: 15px 35px 15px 15px;
  gap: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.prevNxtButton{
  display: flex;
  background-color: #4FAAC5;
  border-radius: 45px;
  padding: 5px 8px;
  cursor: pointer;
  transition: 100ms;
  &:active{
    filter: brightness(105%);
  }
  &:hover{
    background-color: #3191AD;
  }
}

.pageButton{
  display: flex;
  background-color: #4FAAC5;
  border-radius: 100%;
  color: #fff;
  justify-content: center;
  align-items: center;
  width: 31px;
  height: 31px;
  cursor: pointer;
  transition: 200ms;
  &:hover{
    background-color: #3191AD;
  }
}

.searchBox{
  display: flex;
  flex-direction: row;
  font-size: 16px;
  justify-content: start;
}
