.table{
  overflow-x: auto;
}

.theadRow,
.tbodyRow{
  display: flex;
  gap: 5px;
  place-content: center;
}

.tbodyRow{
  cursor: auto !important;
}

.small{
  padding: 0 !important;
  text-align: center;
  width: 45px !important;
}

.middle{
  padding: 0 !important;
  text-align: center;
  width: 85px !important;
}

.large{
  padding: 0 !important;
  text-align: center;
  width: 225px !important;
  overflow-y: auto;
  
}

.theadCell{
  font-size: 16px !important;
  padding: 0 !important;
}

.small,
.middle,
.large{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem !important;
  font-size: 14px !important;
}