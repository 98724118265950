@import "assets/Colors/index.scss";

.headerContent{
  display: flex;
  justify-content: space-around;
  margin: 0;
  width: 100%;
  background-color: $secondaryLight !important;
}

.headerLogo{
  display: flex;
  justify-content: space-around;
  height: 80%;
}

.logo{
  height: 10px !important;
}

.navlink{
  color: $white !important;
  text-decoration: none;
}

.navlinkActive{
  color: $white !important;
  text-decoration: underline;
}

.navContainer{
  gap: 10px;
  align-items: baseline;
}