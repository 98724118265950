@import 'assets/Colors/index.scss';

.header{
  background-color: $secondaryLight;
  height: 71px;
  padding: 12px 0 0 32px;
  font-size: 32px;
  font-weight: 700;
  color: $white;
  margin-bottom: 11px;
  text-align: center;
  align-self: center;
}

.support{
  display: flex;
  flex-direction: row;
  background-color: #F3F3F3;
  min-height: 61px;
  max-height: fit-content;
  border-radius: 5px;
  border: 1px solid #D7D7D7;
  margin-bottom: 1px;
  justify-content: center;
  align-items: center;
}

.info{
  display: flex;
  flex: 4;
  gap: 10px;
  justify-content: center;
  align-items: center;
  max-height: 90px;
  overflow-y: auto;
}

.infoField{
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: fit-content;
}

.question{
  display: flex;
  flex-direction: row;
  flex: 5;
  gap: 5px;
  max-height: 90px;
  align-items: center;
}

.questionField{
  flex: 5;
  margin: 0 5px;
  text-align: center;
  word-break: break-word;
  overflow-y: auto;
}