.container{
  margin-top: 5rem;
}

.linksVideos{
  margin: 1rem 0 3rem 0.5rem;
}

.videosName{
  font-size: 14px;
}

.videosName a{
  font-weight: bold;
  margin-left: 0.5rem;
}