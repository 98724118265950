@import "../../assets/Colors/index.scss";

.Card{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  padding: 0;
  margin: 0;
  border-radius: 500px;
  height: 36px;
}

.card_content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 2px;
}

.time{
  display: flex;
  color: $white;
  justify-content: center;
  flex: 1;
}

.temp{
  display: flex;
  justify-content: center;
  align-items: center;
  color: $text;
  background-color: $white;
  border-radius: 500px;
  padding: 0 10px;
  height: 32px;
}