@import '../../assets/Colors/index.scss';

.card{
  margin: 5px !important;
}

.header{
  padding: 4px 4px !important;
}

.title{
  color: #eee;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  transition: all 0.1s linear;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.title:hover{
  color: rgb(184, 184, 184);
  cursor: pointer;
}

.fontTitle{
  font-size: 16px;
  color: #eee;
}

.deviceName{
  font-size: 14px;
  text-transform: uppercase;
}

.deviceHeader{
  background-color: $primary;
}

.cardBody{
  color: $primary;
  padding: 8px !important;
}

.cardBodyText,
.cardBodyTextDisconnect
{
  display: flex;
  justify-content: center;
  gap: 10%;
}
  
.cardBodyTextDisconnect{
  color: grey;
}

.cardBodyText{
  color: $primary;
}

.arrowDown{
  color: #aaa;
}

.arrowDown:hover{
  cursor: pointer;
}

.dropDownOpen{
  display: flex;
  justify-content: space-around;
}

.formSwitch{
  display: flex !important;
  justify-content: center !important;
  margin-top: 1rem !important;
  gap: 0.5rem !important;
}

.rowSlider{
  margin:  1rem 0 !important;
}

.slider{
  width: 70% !important;
  margin: auto 0 !important;
}

.gauging{
  color: $text !important;
  justify-content: center !important;
}

.rowCenter{
  justify-content: center !important;
}

.calendar{
  margin-left: 10px;
  margin-top: 2px;
}

.handPointingUp{
  position: absolute;
  margin-top: 11px;
}

.travel{
  margin-left: 10px;
  height: 0;
}