.container,
.form{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
}

.inputStandard{
  margin: 1.5rem 0;
}



.form{
  width: 100%;
}