@import "assets/Colors/index.scss";

.searchDeviceForm,
.searchDeviceBar,
.searchDeviceButton {
  display: flex;
  justify-content: center;
}

.searchDeviceForm {
  margin: 0 auto;
  margin-top: 10vh;
};

.searchDeviceButton {
  margin: 20px auto;
}

.title{
  text-align: center;
  color: $text !important;
  margin-bottom: 20px;
}