@import 'assets/Colors/index.scss';

.body{
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 80px 1rem 1rem 1rem !important;
}

.back{
  position: relative;
  width: 50px;
}

.openForm{
  display: flex;
  justify-content: end;
  gap: 15px;
}

.row{
  width: 100%;
}

.button{
  background-color: $primary !important;
  color: $white !important;
}

.showDevices{
  margin: 0.5rem 1rem !important;
}

.textUsername{
  text-align: left;
}

.textEmail{
  text-align: center;
}

.textName{
  text-align: right;
}

@media (max-width:768px) {
  .textUsername{
    text-align: center;
  }
  .textEmail{
    text-align: center;
  }
  .textName{
    text-align: center;
  }
}
