.lastUpdate{
  padding: 0 12px;
  display: flex;
  margin-top: 1rem;
}

.colDelete{
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width:768px) {
  .colDelete{
    justify-content: center;
  }
}

.modalFooter{
  display: flex;
  justify-content: space-between;
}

.centerText{
  display: flex;
  justify-content: center;
}

.clientInfos{
  justify-content: space-between;
  text-align: center;
}

.container{
  margin-top: 5rem !important;
}

.alertMessage{
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}