@import "assets/Colors/index.scss";


.headerContent{
    display: flex;
    justify-content: center;
    margin-top: 56px;
    width: 100%;
    background-color: #88C6D9 !important;
}

.navContainer{
    gap: 20px;
    align-items: baseline;
    justify-content: center;
}

.navlink{
    color: $white !important;
    text-decoration: none;
}

.navlinkActive{
    color: $white !important;
    text-decoration: underline;
}