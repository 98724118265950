.signinContent{
  display: flex;
  justify-content: center;
  margin: 5% auto;
  min-height: 50vh;
}

.signinInput{
  margin: 5% 0;
}

.signinSubmit{
  width: 100%;
}

.forgotPasswordRow{
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}