.body{
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.back{
  position: relative;
  width: 50px;
}

.row{
  width: 100%;
}

.message{
  display: grid;
  justify-items: center;
}